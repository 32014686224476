import { boolean, number, object, string } from 'yup';
import * as validateCard from 'card-validator';

export const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
const addressNameRegex = /(\w.+\s).+/i;
// https://stackoverflow.com/questions/578406/what-is-the-ultimate-postal-code-and-zip-regex
export const zipRegex = /^[A-Za-z0-9][A-Za-z0-9\- ]{0,10}[A-Za-z0-9]$/;

export const addressSchema = object().shape({
  name: string()
    .required('Name is required')
    .max(35, 'Name line must be at most 35 characters')
    .matches(addressNameRegex, 'Name must have at least a first and last name'),
  company: string().nullable().max(35, 'Company line must be at most 35 characters'),
  address1: string().required('Address is required').max(35, 'Address line must be at most 35 characters'),
  address2: string().nullable().max(35, 'Address line must be at most 35 characters'),
  city: string().required('City is required'),
  stateId: number().required('State is required'),
  countryId: number().required('Country is required'),
  zipcode: string().required('ZIP code is required').matches(zipRegex, 'ZIP code is not valid'),
  phone: string().required('Phone number is required').matches(phoneRegex, 'Phone number is not valid'),
  residential: boolean(),
});

export const creditCardSchema = object().shape({
  name: string().required('Name is required'),
  number: string()
    .test('test-number', 'Credit Card number is invalid', value => validateCard.number(value).isValid)
    .required('Credit Card number is required'),
  verificationValue: string()
    .required('Security Code is required')
    .min(3, 'Security Code must be 3-4 digits')
    .max(4, 'Security Code must be 3-4 digits'),
  month: number().required('Month is required'),
  year: number().required('Year is required'),
  ccType: string(),
  saveCreditCard: boolean(),
});
