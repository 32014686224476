import { Field, Form, Formik, FormikValues } from 'formik';
import Grid from 'styleguide/components/Grid/Grid';
import Combobox from 'styleguide/components/Formik/Combobox/Combobox';
import Button from 'styleguide/components/Button/Button';
import * as React from 'react';
import { updateDefaultAddresses } from 'api/account/orders';
import UserContext from 'contexts/UserContextContainer/UserContext';
import { number, object } from 'yup';
import { Status } from 'libs/utils/api/types';

const DefaultAddressesForm = () => {
  const { currentUser, updateAddresses } = React.useContext(UserContext);

  const addressOptions = currentUser.addresses.map(elem => ({
    key: elem.id,
    label: elem.description,
  }));

  const defaultAddressSchema = object().shape({
    shipAddressId: number().nullable(),
    billAddressId: number().nullable(),
  });
  const onSubmit = (values: FormikValues) => {
    updateDefaultAddresses(values).then(res => {
      if (res.status === Status.Ok) {
        updateAddresses(
          res.payload.addresses.filter(a => a.verified),
          res.payload.defaultShipAddress,
          res.payload.defaultBillAddress,
        );
      }
    });
  };

  return (
    <Formik
      initialValues={{
        shipAddressId: currentUser.defaultShipAddress ? currentUser.defaultShipAddress.id : null,
        billAddressId: currentUser.defaultBillAddress ? currentUser.defaultBillAddress.id : null,
      }}
      onSubmit={values => onSubmit(values)}
      validationSchema={defaultAddressSchema}
    >
      <Form>
        <Grid.Container>
          <Grid>
            {addressOptions && (
              <Grid.Row>
                <Grid.Col sm={12} md={4} className="mt-5 -md:mt-3" data-cy="defaultShipAddressSelectParent">
                  <Field
                    name="shipAddressId"
                    options={addressOptions}
                    component={Combobox}
                    label="Default Ship Address"
                    data-cy="defaultShipAddressSelect"
                    inPlaceError
                  />
                </Grid.Col>
                <Grid.Col sm={12} md={4} className="mt-5 -md:mt-3" data-cy="defaultBillAddressSelectParent">
                  <Field
                    name="billAddressId"
                    options={addressOptions}
                    component={Combobox}
                    label="Default Bill Address"
                    data-cy="defaultBillAddressSelect"
                    inPlaceError
                  />
                </Grid.Col>
                <Grid.Col sm={12} md={4} className="mt-5 -md:my-3">
                  <Button
                    dataCy="updateDefaultAddressBtn"
                    type="submit"
                    color="blue"
                    className="h-[48px] !py-0"
                  >
                    Update Default Addresses
                  </Button>
                </Grid.Col>
              </Grid.Row>
            )}
          </Grid>
        </Grid.Container>
      </Form>
    </Formik>
  );
};

export default DefaultAddressesForm;
