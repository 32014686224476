import * as React from 'react';
import css from './Action.scss';
import cn from 'classnames';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import './tippy-printivity-theme.css';
import { IconType } from 'react-icons';
import { IconBaseProps } from 'react-icons/lib/esm/iconBase';

interface Props {
  ref?: React.RefObject<HTMLDivElement>;
  action: string;
  icon: IconType;
  CaptionComponent?: React.ReactElement;
  tooltipClassName?: string;
  iconProps?: IconBaseProps;
  iconColor?: string;
  iconSize?: string;
  onClick: () => void;
  setActionClassName?: (className: string) => void;
  classes?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  hoverColor: 'red' | 'green' | 'yellow' | 'blue' | 'grey';
  disabled?: boolean;
  disabledMessage?: string;
  dataCy?: string;
}

// @ts-ignore
const Action = ({ iconProps, CaptionComponent, tooltipClassName, ref, ...props }: Props) => (
  <div className={cn(props.classes)} ref={ref}>
    <Tooltip
      title={props.disabled ? props.disabledMessage : props.action}
      className={cn(tooltipClassName)}
      position="top"
      trigger="mouseenter"
      arrow
      // @ts-ignore
      onShow={() => {
        if (props.onMouseEnter && !props.disabled) {
          props.onMouseEnter();
        }
      }}
      onHide={() => {
        if (props.onMouseLeave && !props.disabled) {
          props.onMouseLeave();
        }
      }}
      // @ts-ignore
      theme={props.hoverColor ? props.hoverColor : 'printivity'}
    >
      <props.icon
        className={cn(css.icon, css[props.hoverColor])}
        size={props.iconSize}
        color={props.iconColor}
        onClick={event => {
          if (!props.disabled) {
            props.onClick();
            event.stopPropagation();
          }
        }}
        data-cy={props.dataCy}
        {...iconProps}
      />
      {CaptionComponent || null}
    </Tooltip>
  </div>
);

export default Action;
